<template>
    <el-container>
        <el-main>
            <el-tabs v-model="activeName" @tab-click="handleTabClick">
                <el-tab-pane label="打印设备" name="Device">
                    <el-form ref="form" :model="form" label-width="160px" style="display: flex;" :rules="rules">
                        <div>
                            <el-form-item label="标签打印机型号：" required>
                                <div>
                                    <div>飞鹅N20W</div>
                                    <img src="http://img14.360buyimg.com/n1/s450x450_jfs/t1/62356/3/19521/27957/62c15a2cE276f90a7/0e00f24c3d482738.jpg"
                                        class="image">
                                    <div class="title">标签机连接方式：
                                        <el-button type="primary" round style="margin-left: 200px;"
                                            @click="dialogVisible = true">查看操作视频</el-button>
                                    </div>
                                    <ul class="list">
                                        <li>1.把标签机的适配器和电源连接起来，并通电；</li>
                                        <li>2.装纸，按照操作视频；</li>
                                        <li>3.根据小票提示操作，微信扫码即可完成WIFI配置。</li>
                                    </ul>
                                </div>
                            </el-form-item>
                            <el-form-item label="设备备注名称：">
                                <el-input v-model="form.remark" class="inputClass"></el-input>
                            </el-form-item>
                            <el-form-item label="设备机身号：" prop="sn">
                                <el-input v-model="form.sn" class="inputClass"></el-input>
                            </el-form-item>
                            <el-form-item label="设备KEY：" prop="key">
                                <el-input v-model="form.key" class="inputClass"></el-input>
                            </el-form-item>
                        </div>
                        <div style="width: 400px;">
                            <el-form-item label="打印模板：" required>
                                <div class="ticket">
                                    <div style="line-height: 20px;">店铺名称#</div>
                                    <div class="goods">商品###########</div>
                                    <div class="zuofa">规格####，做法####，####，####，####，####，####，####，####</div>
                                    <div class="row">
                                        <div>10-30 11:11:11</div>
                                        <div>￥200.27</div>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="打印规则设置" name="Rulesetting">
                    <el-form label-width="160px">
                        <!--<el-form-item label="打印张数：" required>
                            <el-input v-model="form_num" class="inputClass"></el-input>
                        </el-form-item>-->
                        <el-form-item label="打印设备：" prop="code" required>
                            <el-table fit :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" class="table">
                                <template slot="empty">
                                    <div class="empty">没有检测到打印机，请添加打印机</div>
                                </template>
                                <el-table-column prop="name" label="设备备注名称" align="center">
                                    <template>
                                        <div class="flex">
                                            <i class="el-icon-printer"></i>
                                            <span style="margin-left: 5px;">{{ printerInfo.remark }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="state" label="连接状态" align="center">
                                    <template slot-scope="scope">
                                        <div class="flex">
                                            <i :class="[scope.row.ret == 0 ? 'el-icon-success' : 'el-icon-error']"></i>
                                            <span style="margin-left: 5px;">{{ scope.row.ret == 0 ? scope.row.data :
                                                scope.row.msg }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
            <el-dialog title="操作视频" :visible.sync="dialogVisible" width="30%">
                <video
                    src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/Tengchain/%E6%A0%87%E7%AD%BE%E6%89%93%E5%8D%B0%E6%9C%BA.mp4"
                    style="width:400px;height:600px;" controls></video>
            </el-dialog>
            <Preservation @preservation="preservation"></Preservation>
        </el-main>
    </el-container>
</template>

<script>
import Preservation from '@/components/preservation'

export default {
    components: {
        Preservation
    },
    data () {
        return {
            activeName: "Device",
            printerInfo: {},
            form: {
                remark: '',
                sn: '',
                key: ''
            },
            list: [],
            dialogVisible: false,
            rules: {
                sn: [{ required: true, message: '请填写设备机身号', trigger: 'blur' }],
                key: [{ required: true, message: '请填写设备KEY', trigger: 'blur' }]
            }
        };
    },
    created () {
        this.getPrinterInfo()
    },
    methods: {
        getPrinterInfo () {
            this.$axios.post(this.$api.catering.LabelPrinterInfo)
                .then(res => {
                    if (res.code == 0) {
                        if (res.result) {
                            this.printerInfo = res.result
                            this.list = [res.result.data]
                            this.form = {
                                remark: res.result.remark,
                                sn: res.result.sn,
                                key: res.result.key
                            }
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        preservation () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$axios.post(this.$api.catering.LabelPrinterEdit, this.form)
                        .then(res => {
                            if (res.code == 0) {
                                this.$message.success('保存成功');
                                this.getPrinterInfo()
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>
.el-container {
    height: 100%;
}

.el-main {
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px 40px 70px;
}

.image {
    width: 180px;
    height: 180px;
    border: 1px solid #ccc;
    margin: 8px 0;
}

.title {
    font-weight: bold;
    display: flex;
}

.list {
    line-height: 22px;
    margin-top: 5px;
}

.inputClass {
    width: 600px;
}

.el-form-item {
    margin-bottom: 0 !important;

    &+.el-form-item {
        margin-top: 22px !important;
    }
}

.ticket {
    margin: 0 auto;
    width: 260px;
    border: 1px solid #ddd;
    background-color: #fafafa;
    padding: 20px 15px;
    font-size: 14px;
    color: #555;
    overflow: hidden;
    border-radius: 14px;

    .goods {
        font-weight: bold;
        font-size: 18px;
        margin: 6px 0;
    }

    .zuofa {
        line-height: 20px;
    }

    .row {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        line-height: 20px;
    }
}

/deep/.el-form-item__error {
    position: relative !important;
}
</style>
